<template>
  <f7-page class="profile-order-page" infinite :infinite-distance="50" :infinite-preloader="showPreloader" @infinite="loadMore">
    <template #fixed>
      <ProfileNavigationComponent :type="navType" :title="$t.getTranslation('LBL_ORDER')" :settings="false" />
    </template>

    <section class="profile-container">
      <div class="container">
        <f7-list no-hairlines>
          <f7-list-input v-model:value="searchValue" type="text" :placeholder="$t.getTranslation('LBL_SEARCH')" clear-button outline @keyup.enter="onSearch">
            <template #content-start>
              <font-awesome-icon class="search" :icon="['far', 'search']" fixed-width />
            </template>
          </f7-list-input>
        </f7-list>

        <div class="profile-content-container">
          <template v-if="orderList && orderList.length > 0">
            <div v-for="order in orderList" :key="'ord_' + order.OrderKey">
              <OrderItemCardComponent :data="order" />
            </div>
          </template>

          <NoDataFoundComponent v-else :size="'sm'" :title="$t.getTranslation('LBL_ORDER_EMPTY')" />
        </div>
      </div>
    </section>
  </f7-page>
</template>

<script>
import { defineComponent, onMounted, ref, defineAsyncComponent, watch } from "vue";
import { get } from "@/utils/axios";

const ProfileNavigationComponent = defineAsyncComponent(() => import(/* webpackChunkName: "profile-nav" */ /* webpackMode: "lazy" */ "@/components/navigations/ProfileNavigationComponent.vue"));
const NoDataFoundComponent = defineAsyncComponent(() => import(/* webpackChunkName: "no-data" */ /* webpackMode: "lazy" */ "@/components/NoDataFoundComponent.vue"));
const OrderItemCardComponent = defineAsyncComponent(() => import(/* webpackChunkName: "order-item-card" */ /* webpackMode: "lazy" */ "@/components/cards/OrderItemCardComponent.vue"));

export default defineComponent({
  name: "ProfileOrderPage",
  components: {
    ProfileNavigationComponent,
    OrderItemCardComponent,
    NoDataFoundComponent,
  },
  props: { f7router: Object, f7route: Object },
  setup(props) {
    const showPreloader = ref(false);

    let navType = ref("back");
    if (props.f7route?.query?.callback) {
      navType.value = "backProfile";
    }

    let page = 1;
    let lastPage = 1;
    let size = 15;

    const searchValue = ref("");
    let searchTimeout = false;

    const orderList = ref([]);
    const orderMode = ref("all");
    const isFetching = ref(false);

    const getOrderList = async () => {
      if (isFetching.value) return true;
      isFetching.value = true;

      let currentMode = orderMode.value == "all" ? "" : orderMode.value;
      let returnData = await get("/order/datalist", {
        mode: currentMode,
        page: page,
        size: size,
        search: searchValue.value,
      });

      if (returnData && returnData.data && Array.isArray(returnData.data)) {
        orderList.value = [...orderList.value, ...returnData.data];

        isFetching.value = false;
        lastPage = returnData.lastPage;
        page++;
      }
    };

    const loadMore = () => {
      return lastPage >= page ? getOrderList() : false;
    };

    onMounted(() => {
      if (props?.f7route?.query?.mode) {
        orderMode.value = props?.f7route?.query?.mode;
      }

      getOrderList();
    });

    watch(searchValue, (newValue, oldValue) => {
      if (searchTimeout) clearTimeout(searchTimeout);

      searchTimeout = setTimeout(function () {
        if (newValue == null) {
          return true;
        }

        onSearch();
      }, 800);
    });

    const onSearch = () => {
      if (searchTimeout) clearTimeout(searchTimeout);

      page = 1;
      lastPage = 1;
      orderList.value = [];

      getOrderList();
    };

    return {
      searchValue,
      showPreloader,
      navType,
      orderList,
      orderMode,
      getOrderList,
      loadMore,
      onSearch,
    };
  },
});
</script>
